/*! dashboard.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Dashboard core file 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. CSS Imports
1. Resets and base setup
=============================================================================
***/

/* ==========================================================================
0. CSS Imports
========================================================================== */

@import "partials/colors";
@import "partials/dashboard";
@import "partials/pageloader";
@import "partials/tabs";
@import "partials/dropdowns";
@import "partials/buttons";
@import "partials/animations";
@import "partials/cards";
@import "partials/forms";
@import "partials/accordion";
@import "partials/dialogs";
@import "partials/labels";
@import "partials/lists";
@import "partials/tables";
@import "partials/messages";
@import "partials/dashboard-layout";
@import "partials/dashboard-sidebar";
@import "partials/dashboard-navbar";
@import "partials/dashboard-right-sidebar";
@import "partials/dashboard-feed";
@import "partials/dashboard-widgets";
@import "partials/dashboard-charts";
@import "partials/dashboard-documents";
@import "partials/dashboard-projects";
@import "partials/dashboard-contacts";
@import "partials/dashboard-kanban";
@import "partials/dashboard-deal";
@import "partials/dashboard-forum";
@import "partials/dashboard-support";
@import "partials/dashboard-datatables";
@import "partials/dashboard-mailbox";
@import "partials/dashboard-chat";
@import "partials/dashboard-utils";
@import "partials/helpers";
@import "partials/quickview";
@import "partials/timeline";
@import "partials/switch";
@import "partials/extensions-checkboxes";
@import "partials/dashboard-responsive";

/* ==========================================================================
1. Resets and base setup
========================================================================== */

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.no-touch .scrollable.hover {
    overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
    overflow-y: auto;
    overflow: visible;
}

a {
    color: $secondary;

    &:hover {
        color: $accent;
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }
}

nav {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

nav ul, nav li {
    outline: 0;
    margin: 0;
    padding: 0;
}

body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    overflow: hidden !important;
    background-color: $dashboard-grey;
    margin: 0 !important;
    height: 100% !important;
}

@font-face {
    font-family: 'Nexa Light';
    src: url("../fonts/text/nexa/NexaLight.woff") format("woff"), url("../fonts/text/nexa/NexaLight.ttf") format("truetype");
}

@font-face {
    font-family: 'Nexa Bold';
    font-weight: bold;
    src: url("../fonts/text/nexa/NexaBold.woff") format("woff"), url("../fonts/text/nexa/NexaBold.ttf") format("truetype");
}

.customer-search {
    .input {
        padding-left: 15px;
    }
}

input.input[readonly] {
    background-color: #eee;
}

.selected-datepicker {
    margin-bottom: 20px;

    input {
        font-weight: bold;
    }
}

.order-card {
    &.packed {
        box-shadow: 0 0 1px 1px #69bd69d7;
        background-color: #EEEEEE;
    }

    &.invoiced {
        background-color: #69bd6921;
    }

    &.edited-after-packed {
        background-color: #ffce6536;
    }

    .invoiced-status {
        padding: 0 15px 10px 0;
        
        i.im {
            font-size: 1.3em;
            padding: 2px;

            &.im-icon-Coins-2 {
                color: #999999;
                cursor: pointer;
                border: 1px solid #CCCCCC;
                border-radius: 5px;
            }

            &.im-icons-Coin {
                color: #000000;
            }
        }
    }

    padding: 5px 10px;
    margin-bottom: 10px;

    .columns {
        margin: 0 0 10px 0;

        .column {
            padding: 0;
        }
    }

    > div {
        > div {
            display: inline-block;
        }
    }

    .data-customer {
        .data-customer-name {
            font-size: 1.3em;
            font-weight: bold;
        }
    }

    .data-product {
        .data-product-name {
            font-size: 1em;
            font-weight: bold;
        }

        .data-product-plu {
            font-size: 0.9em;
        }
    }

    .data-packing {
        .padded {
            padding: 0 5px;

            .button {
                &.is-fullwidth {
                    height: 2.5em;
                }
            }
        }
    }

    .data-invoiced {
        position: absolute;
        bottom: 10px;
        right: 0
    }

    i {
        &.fa {
            &.fa-cog {
                cursor: pointer;
                color: #68a2ff;
                font-size: 1.5em;
            }
        }
    }
}

.checkbox-wrap {
    .title {
        font-size: 1em;
        font-weight: normal;
    }
}

.new-order {
    &.flex-card {
        padding: 20px;
        margin: 0;
        overflow: visible;

        .column {
            margin: 10px 0;

            &.is-offset-8 {
                margin-left: 66.66666667%;
            }

            &.no-pad {
                padding: 0 0 0 12px;
                margin: 0;
            }
        }

        .adhoc-customer-checkbox {
            padding-top: 10px; 
            
            .b-checkbox {
                label {
                    padding-left: 10px;
                }
            }
        }
    }
}

.field {
    .control {
        &.has-icons-left {
            .chosen-container {
                a.chosen-single {
                    padding-left: 30px;
                }

                &+.icon {
                    height: 3em;
                    width: 2.5em;
                    
                    .fa {
                        font-size: 1.3em;
                    }
                }
            }
        }

        .chosen-container {
            &.chosen-disabled {
                a.chosen-single {
                    background-color: #f3f3f3;
                    border: 1px solid #f3f3f3;
                }
            }

            a.chosen-single {
                span {
                    color: #7a7a7a;
                }

                &.chosen-default {
                    span {
                        color: #cecece;
                    }
                }
            }
        }
    }
}